<template>
  <div class="admin-block">
    <div>
      <input type="file" accept=".ino.bin" @change="updateFile($event)"/>
    </div>
    <div>
      Type: <input type="text" v-model="selectedType"/>
    </div>
    <div>
      Force for all devices: <input type="checkbox" name="type" v-model="forceFw"/>
    </div>
    <div>
      <button @click="doUpload" :disabled="!canUpload()">Upload new Firmware</button>
    </div>
  </div>
</template>

<script setup>
import {api} from '@/api.js';
import {ref} from 'vue';

const selectedFile = ref(null);
const selectedType = ref(null);
const forceFw = ref(true);

function updateFile(event) {
  const file = event.target.files?.[0];
  selectedFile.value = file;
  selectedType.value = file ? file.name.replace('.ino.bin', '') : '';
}

function canUpload() {
  return selectedType.value && selectedFile.value;
}

function doUpload() {
  const data = new FormData();
  data.append('firmware', selectedFile.value);
  forceFw.value && data.append('forceDownload', true);
  api.put(`/types/${encodeURIComponent(selectedType.value)}/firmware`, data, false);
}
</script>

<style scoped>
.admin-block {
  background-color: #ffb4e6;
  padding: 10px;
}

.admin-block > div {
  margin: 5px;
}
</style>
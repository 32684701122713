<template>
  <div class="device-view">
    <div class="version">Version: {{device.version}}</div>
    <div class="uid" v-if="isAdmin">UID: {{device._id}}</div>
    <div class="last-seen">
      Last seen: {{new Date(device.lastRequest).toLocaleString("et-EE")}}
      <span v-if="device.offline" class="offline"> (offline)</span>
    </div>

    <div v-if="device.forceFw">
      <div class="forced clickable" @click="discardFirmwareForcing">Firmware update forced📲</div>
    </div>
    <div v-if="device.forceConf">
      <div class="forced clickable" @click="discardConfForcing">Config update forced⚙️</div>
    </div>

    <div class="config-table">
      <div class="table-row">
        <div class="table-title">Key</div>
        <div class="table-title">Value</div>
      </div>
      <div class="table-row"
           v-for="key in Object.keys(configToModify)"
           :key="key">
        <div class="table-cell" v-text="key"/>
        <div class="table-cell">
          <input v-model="configToModify[key]"/>
          <div v-if="isNameUpdating(key)">
            <input type="checkbox" v-model="shouldUpdateReadings"/>Update readings?
          </div>
        </div>
      </div>
    </div>

    <button @click="saveConfig" :disabled="!isConfigModified">Save config</button>
    <button @click="markAsOffline" :disabled="device.offline">Mark as offline</button>
    <button @click="forceUpdateFirmware" v-if="isAdmin">Update Firmware</button>
    <button @click="deleteInsaneReadings">Cleanup readings</button>
    <button @click="deleteAllReadings">Remove all readings</button>
  </div>
</template>

<script setup>
import {api} from '@/api.js';
import {clone, Home} from '@/common.js';
import {compositions} from '@/components/compositions.js';
import {computed, ref, watch} from 'vue';

const props = defineProps(
    {
      deviceId: {type: String, required: true},
    });

const { discardConfForcing, discardFirmwareForcing } = compositions.devices(props.deviceId);

const device = computed(() => Home.devices[props.deviceId]);

const isAdmin = computed(() => Home.user.isAdmin);

const shouldUpdateReadings = ref(false);

const configToModify = ref(cloneConfig());
watch(props, () => configToModify.value = cloneConfig());

const isConfigModified = computed(() => JSON.stringify(cloneConfig()) !== JSON.stringify(configToModify.value));

const isNameModified = computed(() => device.value.config.name !== configToModify.value.name);

function isNameUpdating(key) {
  return key === 'name' && isNameModified.value;
}

async function saveConfig() {
  let updateReadings = isNameModified.value && shouldUpdateReadings.value;
  await api.patch(`/devices/${props.deviceId}/config`, {
    config: configToModify.value,
    updateReadings: updateReadings,
  });
  updateReadings && window.location.reload();
}

function forceUpdateFirmware() {
  return api.post(`/devices/${props.deviceId}/firmware`);
}

function markAsOffline() {
  return api.post(`/devices/${props.deviceId}/offline`);
}

async function deleteInsaneReadings() {
  await api.delete(`/devices/${props.deviceId}/readings?filter=insane`);
  window.location.reload();
}

async function deleteAllReadings() {
  if (window.confirm(`Really delete all readings for ${device.value.name}?`)) {
    await api.delete(`/devices/${props.deviceId}/readings?filter=all`);
    window.location.reload();
  }
}

function cloneConfig() {
  const copy = clone(device.value.config);
  delete copy.signature;
  !isAdmin.value && delete copy.type;
  return copy;
}
</script>

<style scoped>
.device-view {
  margin-top: 5px;
  background-color: antiquewhite;
  padding: 5px;
}

.version, .last-seen, .uid {
  font-size: smaller;
}

.forced {
  font-weight: bold;
  padding: 3px 0;
}

.config-table {
  display: table;
  border-spacing: 2px;
}

.table-row {
  display: table-row;
}

.table-title, .table-cell {
  display: table-cell;
  border: 1px solid darkgreen;
  padding: 2px;
}

.table-title {
  text-align: center;
  font-weight: bold;
}

.offline {
  font-weight: bold;
}
</style>

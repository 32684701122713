<template>
  <div v-if="Home.user" class="top-bar">
    <div class="user">Hello, {{Home.user.name}}</div>
    <div v-text="icon" @click="initServiceWorker" style="cursor: pointer"/>
    <div><button @click="doSignOut()">Log out</button></div>
  </div>
</template>

<script setup>
import {api} from '@/api.js';
import {computed, onMounted, ref} from 'vue';
import {Home} from '@/common.js';
import {notifications} from '@/components/notifications.js';
import {signOut} from 'aws-amplify/auth';

onMounted(async () => {
  await api.get('/users/current');
  // return initServiceWorker();
});

const icon = ref(getNotificationsIcon(Notification.permission));

const subscriptions = computed(() => {
  return Home.user.pushSubscriptions.reduce(
      (record, item) => {
        record[item.data.endpoint] = item;
        return record;
      },
      {});
});

async function doSignOut() {
  await notifications.removeServiceWorker();
  await signOut();
  window.location.reload();
}

async function initServiceWorker() {
  try {
    const permission = await notifications.getNotificationPermission();
    icon.value = getNotificationsIcon(permission);
    permission === 'granted' && await notifications.registerServiceWorker(Object.keys(subscriptions.value));
  }
  catch (e) {
    alert("error: " + e.message);
  }
}

function getNotificationsIcon(permission) {
  switch (permission) {
    case 'granted': return '🔔';
    case 'denied': return '🚫';
    default: return '🔕';
  }
}
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;

  background-color: aliceblue;
  padding: 5px;
}

.user {
  font-variant: all-small-caps;
}
</style>
import {fetchAuthSession} from '@aws-amplify/auth';
import {updateHomeData} from '@/common.js';
import appConfig from '@/app.config.js';

async function getAuthHeader() {
  const session = await fetchAuthSession();
  return session.tokens?.idToken?.toString();
}

async function apiCall(path, type = 'GET', body = undefined, isJson = true) {
  const headers = {
    Authorization: await getAuthHeader(),
  };
  const contentType = prepareContentType(isJson);
  contentType && (headers['content-type'] = contentType);
  const response = await fetch(appConfig.apiEndpoint + path, {
    method: type,
    headers: headers,
    body: prepareBody(body, isJson),
  });
  if (!response.ok) {
    alert(`Error: \n${await response.text()}`);
  }
  else {
    await updateHomeData(await response.json());
  }
}

function prepareContentType(isJson) {
  return isJson ? 'application/json' : undefined;
}

function prepareBody(body, isJson) {
  if (body === undefined || body === null) {
    return undefined;
  }
  else {
    return isJson ? JSON.stringify(body) : body;
  }
}

export const api = {
  get(path) {
    return apiCall(path);
  },

  post(path, body = undefined, isJson = true) {
    return apiCall(path, 'POST', body, isJson);
  },

  patch(path, body= undefined, isJson = true) {
    return apiCall(path, 'PATCH', body, isJson);
  },

  put(path, body= undefined, isJson = true) {
    return apiCall(path, 'PUT', body, isJson);
  },

  delete(path) {
    return apiCall(path, 'DELETE');
  },
};

import {api} from '@/api.js';

export const compositions = {
  devices(deviceId) {
    const discardConfForcing = () => api.delete(`/devices/${deviceId}/config`);
    const discardFirmwareForcing = () => api.delete(`/devices/${deviceId}/firmware`);

    return { discardConfForcing, discardFirmwareForcing };
  },
};

<template>
  <TopBar/>
  <HistoryBlock/>
  <DevicesBlock/>
  <AdminBlock v-if="Home.user?.isAdmin"/>
</template>

<script setup>
import AdminBlock from '@/components/AdminBlock.vue';
import DevicesBlock from '@/components/DevicesBlock.vue';
import HistoryBlock from '@/components/HistoryBlock.vue';
import TopBar from '@/components/TopBar.vue';
import {api} from '@/api.js';
import {Home} from '@/common.js';
import {onMounted} from 'vue';

onMounted(() => api.get('/readings'));
</script>

<style>
.clickable {
  cursor: pointer;
  background-color: #ADD8E688;
  padding: 2px;
  border-radius: 1px;
  margin: 2px 0;
  user-select: none;
}

input {
  background-color: #ffffffaa;
  border: none;
}
</style>

// good tutorial: https://medium.com/@a7ul/beginners-guide-to-web-push-notifications-using-service-workers-cb3474a17679
import appConfig from '@/app.config.js';
import {api} from '@/api.js';

const serviceWorkerUrl = 'sw.js';

function checkNotifications() {
  if (!('Notification' in window)) {
    throw new Error('No Notification support!');
  }
}

function checkServiceWorker() {
  if(!('serviceWorker' in navigator)) {
    throw new Error('No Service Worker support!');
  }
  if(!('PushManager' in window)) {
    throw new Error('No Push API Support!');
  }
}

async function registerPushManager(swRegistration) {
  const options = {
    applicationServerKey: urlB64ToUint8Array(appConfig.vapidPublicKey),
    userVisibleOnly: true,
  };
  return swRegistration.pushManager.subscribe(options);
}

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const notifications = {
  async getNotificationPermission() {
    checkNotifications();
    return Notification.requestPermission();
  },

  async registerServiceWorker(existingSubscriptionEndpoints) {
    checkServiceWorker();
    const swRegistration = await navigator.serviceWorker.register(serviceWorkerUrl);
    await navigator.serviceWorker.ready;
    const subscription = await registerPushManager(swRegistration);
    if (!existingSubscriptionEndpoints.includes(subscription.endpoint)) {
      await notifications.rememberSubscription(subscription);
    }
    return swRegistration;
  },

  async removeServiceWorker() {
    const swRegistration = await navigator.serviceWorker.getRegistration(serviceWorkerUrl);
    if (swRegistration) {
      const pushSubscription = await swRegistration.pushManager.getSubscription();
      pushSubscription && await api.delete(`/users/current/subscriptions/${encodeURIComponent(pushSubscription.endpoint)}`);
      await swRegistration.unregister();
    }
  },

  rememberSubscription(subscription) {
    return api.put('/users/current/subscriptions', {
      name: window.navigator.userAgent,
      data: subscription,
    });
  },
};

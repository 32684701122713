import {reactive} from 'vue';

function sortObject(obj) {
  const keys = Object.keys(obj);
  keys.sort((a, b) => a.localeCompare(b));
  const result = {};
  keys.forEach(key => result[key] = obj[key]);
  return result;
}

export const Home = reactive(
  {
    user: null,
    readings: {},
    devices: {},
  },
);

export const updateHomeData = (json) => {
  // console.log('Data from server:', json);
  Object.entries(json).forEach(([key, value]) => {
    switch (key) {
      case 'user':
        Home[key] = value;
        break;
      case 'readings':
        value.forEach(v => Home[key][v._id] = v);
        break;
      case 'message':
        alert(value);
        break;
      default:
        Object.assign(Home[key], value);
        break;
    }
  });
};

export const groupBy = (array, prop) => {
  return sortObject(
    array.reduce(
      (map, element) => {
        const list = map[element[prop]] ?? (map[element[prop]] = []);
        list.push(element);
        return map;
      },
      {}),
  );
};

export const clone = value => JSON.parse(JSON.stringify(value));


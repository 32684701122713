<template>
  <div class="clickable" :class="classes" @click="() => onClick(device._id)">
    <span v-text="device.name"/>
    <span v-if="device.forceFw" title="Firmware update forced">📲</span>
    <span v-if="device.forceConf" title="Config update forced">⚙️</span>
  </div>
</template>

<script setup>
import {computed} from 'vue';

const props = defineProps(
    {
      device: {type: Object, required: true},
      onClick: {type: Function, required: true},
    });

const classes = computed(() => {
  const result = [];
  props.device.forceFw && result.push('firmware');
  props.device.forceConf && result.push('config');
  props.device.offline && result.push('offline');
  return result;
})
</script>

<style scoped>

.firmware {
  text-shadow: 1px 1px 1px goldenrod;
}

.config:not(.firmware) {
  text-shadow: 1px 1px 1px darkgreen;
}

.offline {
  font-style: italic;
  color: darkgray;
}
</style>

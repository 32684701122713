<template>
  <div class="graph">
    <Line :data="data" :options="options" :plugins="plugins"/>
  </div>
</template>

<script>
import {Line} from 'vue-chartjs';
import {
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import {groupBy} from '@/common.js';
import 'chartjs-adapter-date-fns';
import {et} from 'date-fns/locale';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Colors
);

const backgroundPlugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const {ctx} = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color ?? 'floralwhite';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export default {
  components: {
    Line,
  },

  props: {
    type: {type: String, required: true},
    readings: {type: Array, required: true},
  },

  computed: {
    readingsByName() {
      return groupBy(this.readings, 'name');
    },

    data() {
      return {
        datasets: Object.entries(this.readingsByName)
            .map(([name, readings]) => {
              return {
                label: name,
                data: readings.map(r => ({x: r.at, y: r.value})),
                pointRadius: 1.3,
              };
            }),
      };
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.type,
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              font: {
                size: 10,
              },
              usePointStyle: true,
            },
          },
        },

        elements: {
          line: {
            tension: 0.4,
            borderWidth: 1.5,
          },
        },

        scales: {
          x: {
            adapters: {
              date: {
                locale: et,
              },
            },
            type: 'time',
            time: {
              unit: 'hour',
              minUnit: 'hour',
              unitStepSize: 5,
              displayFormats: {
                'day': 'd.MM.',
                'hour': 'H:mm',
              },
              tooltipFormat: 'd. MMM H:mm:ss',
            },
            ticks: {
              major: {
                enabled: true,
              },
              font: (ctx) => ({weight: ctx.tick?.major ? 'bold' : undefined}),
            },
          },
          y: {
            position: 'right',
          },
        },

      }
    },

    plugins() {
      return [backgroundPlugin];
    },
  },
}
</script>

<style>
.graph {
  min-height: calc(min(80vw, 500px));
}
</style>
<template>
  <div class="devices-block">
    <div v-if="devicesArray.length" class="device-types-container">
      <div class="device-type-block" v-for="(devices, type) in devicesByType" :key="type">
        <div class="device-type-title" v-text="type"/>
        <DeviceItem
            v-for="device in devices"
            :key="device._id"
            :device="device"
            :on-click="toggleDeviceId"
        />
      </div>
    </div>
    <span v-else class="clickable" @click="loadDevices">Devices info 🔽</span>

    <div v-if="selectedDeviceId">
      <DeviceView :deviceId="selectedDeviceId"/>
    </div>
  </div>
</template>

<script setup>
import DeviceItem from '@/components/DeviceItem.vue';
import DeviceView from '@/components/DeviceView.vue';
import {api} from '@/api.js';
import {computed, ref} from 'vue';
import {groupBy, Home} from '@/common.js';

const devicesArray = computed(() => Object.values(Home.devices));

const devicesByType = computed(() => groupBy(devicesArray.value, 'type'))

const selectedDeviceId = ref(null);

function loadDevices() {
  return api.get('/devices');
}

function toggleDeviceId(id) {
  selectedDeviceId.value = selectedDeviceId.value === id ? null : id;
}
</script>

<style scoped>
.devices-block {
  background-color: #d1d1ff;
  padding: 10px;
}

.device-types-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.device-type-block {
  background-color: #f1f1f1;
  margin: 1px;
  padding: 8px;
}

.device-type-title {
  font-weight: bold;
  font-variant: all-small-caps;
  margin-bottom: 7px;
}
</style>

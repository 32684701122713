<template>
  <div class="history-block">
    <HistoryChart
        v-for="(readings, type) in readingsByType"
        :key="type"
        :type="type"
        :readings="readings"/>
    <div v-if="!readingsArray.length" class="no-history">No readings history...</div>
  </div>
</template>

<script setup>
import HistoryChart from '@/components/HistoryChart.vue';
import {computed} from 'vue';
import {groupBy, Home} from '@/common.js';

const readingsArray = computed(() => Object.values(Home.readings));

const readingsByType = computed(() => groupBy(readingsArray.value, 'type'));
</script>

<style scoped>
.history-block {
  display: flex;
  flex-direction: column;
  background-color: lavender;
  padding: 5px;
  row-gap: 5px;
}

.no-history {
  font-style: italic;
}
</style>